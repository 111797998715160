import React, { useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useEffectOnce } from 'react-use';
import PropTypes from 'prop-types';
import queryString from 'query-string';
import Error from 'next/error';
import dynamic from 'next/dynamic';
import { Banner } from '@artemis/components/Banner';
import NavBar from '@artemis/components/NavBar';
import NavLogos from '@artemis/components/NavBar/NavLogos';
import PageContainer from '@artemis/components/PageContainer';
import ResponsiveImage from '@artemis/components/ResponsiveImage';
import AccountControls from '@artemis/containers/AccountControls';
import {
  getIncentiveGroups,
  getTotalIncentivesCount,
} from '@artemis/store/incentives/selectors';
import AuthenticationContext from '@artemis/integrations/auth/AuthenticationContext';
import {
  useFormatMessage,
  FormattedMessage,
} from '@artemis/integrations/contentful/utils';
import {
  getUserExternalId,
  getActiveCurrencyCredits,
  getActiveCurrencyCreditsMicro,
} from '@artemis/store/user/selectors';
import { getCountryCodeFromLatLng } from '@artemis/integrations/maps';
import {
  loadNearbyMerchants,
  loadMoreNearbyMerchants,
  loadReorderItems,
  updateCurrencyCode,
} from '@artemis/store/catalog/slice';
import {
  getNearbyMerchantsData,
  getNearbyHasNextPage,
  getNearbyPaginationToken,
  getIsNearbyMerchantsLoading,
  getReorderItems,
  getIsReorderItemsLoading,
} from '@artemis/store/catalog/selectors';
import {
  getActiveOrdersInitialized,
  getOrderSource,
  hasActiveOrders,
} from '@artemis/store/order/selectors';
import { setSource } from '@artemis/store/order/slice';
import CreditIncentiveBanner from '@artemis/containers/Menu/Banner/CreditIncentiveBanner';
import useLoadActiveOrders from '@artemis/containers/OrderBar/useLoadActiveOrders';
import { ORDER_SOURCE } from '@artemis/utils/constants';
import { getCurrencyCodeFromCountryCode } from '@artemis/utils/currency-format';
import { useDownloadAppModal } from '@artemis/utils/downloadApp';
import {
  FILTER_CITY_PARAM,
  FILTER_CUISINE_PARAM,
  FILTER_DELIVERY_PARAM,
  FILTER_LATITUDE_PARAM,
  FILTER_LONGITUDE_PARAM,
  FILTER_OPEN_PARAM,
  FILTER_PICKUP_PARAM,
  FILTER_SEARCH_PARAM,
  FILTER_TOP_PARAM,
} from '@artemis/utils/query/constants';
import usePersistedQuery from '@artemis/utils/query/usePersistedQuery';
import useTrackWebVitals, { PAGE_NAME } from '@artemis/utils/useTrackWebVitals';
import RemoteConfigContext from '@artemis/integrations/remoteConfig/context';
import { LandingPageMetadata } from './LandingPageMetadata';
import { HeroSection } from './HeroSection';
import { logRWSearchEvent, logRWHomescreenImpression } from './analytics';
import { FilterBar } from './FilterBar';
import { Footer } from './Footer';
import { Header } from './Header';
import { QuickReorder } from './QuickReorder';
import { SearchResults } from './SearchResults';
import { QUERY_LIMIT, QUERY_CITIES, QUERY_CUISINES } from './constants';

const OrderBarBanner = dynamic(() =>
  import('@artemis/containers/OrderBar/OrderBarBanner'),
);
const DownloadAppModal = dynamic(() =>
  import('@artemis/components/DownloadApp/DownloadAppModal'),
);

const getHeadingTextKey = query => {
  const { cuisine, city, catering } = query;
  if (cuisine && city)
    return catering
      ? 'landing.headingCuisineCateringInCity'
      : 'landing.headingCuisineCity';
  if (cuisine)
    return catering
      ? 'landing.headingCuisineCatering'
      : 'landing.headingCuisine';
  if (city)
    return catering ? 'landing.headingCityCatering' : 'landing.headingCity';
  if (catering) return 'landing.headingCateringText';
  return 'landing.headingText';
};

const cityQuery = city => {
  if (!city) {
    return '';
  }
  return Object.keys(QUERY_CITIES).includes(city) ? `/city/${city}` : '';
};

const getUrlSearchParams = ({ filter, persistedQuery } = {}) => {
  if (!filter) return '';
  const {
    latitude,
    longitude,
    query,
    queryCuisine,
    hasDelivery,
    hasPickup,
    isTopMerchant,
    isAvailableNow,
  } = filter;
  const showQuery = query && !queryCuisine;
  const queryParams = new URLSearchParams({
    ...(showQuery && { [FILTER_SEARCH_PARAM]: query }),
    ...(isAvailableNow && { [FILTER_OPEN_PARAM]: 1 }),
    ...(hasDelivery && { [FILTER_DELIVERY_PARAM]: 1 }),
    ...(hasPickup && { [FILTER_PICKUP_PARAM]: 1 }),
    ...(isTopMerchant && { [FILTER_TOP_PARAM]: 1 }),
    ...(latitude && { [FILTER_LATITUDE_PARAM]: latitude }),
    ...(longitude && { [FILTER_LONGITUDE_PARAM]: longitude }),
    ...queryString.parse(persistedQuery),
  }).toString();
  return queryParams;
};

const getSearchQueryString = ({ filter, persistedQuery } = {}) => {
  if (!filter) return '';
  const { queryCuisine, city, hasGroupOrdering } = filter;
  const cityString = cityQuery(city);
  const cuisineString = queryCuisine ? `/cuisine/${queryCuisine}` : '';
  const cateringString = hasGroupOrdering ? '/catering' : '';
  return `${cateringString}${cityString}${cuisineString}?${getUrlSearchParams({
    filter,
    persistedQuery,
  })}`;
};

const updateQuery = (filter, persistedQuery) => {
  if (window.history.pushState) {
    const newurl = `/order${getSearchQueryString({ filter, persistedQuery })}`;
    window.history.replaceState({ path: newurl }, '', newurl);
  }
};

export const LandingPage = ({ err, initialFilters = {} }) => {
  const { authenticated, initialized, setAuthModal } = useContext(
    AuthenticationContext,
  );

  const dispatch = useDispatch();

  useLoadActiveOrders();
  const persistedQuery = usePersistedQuery({
    omit: [
      FILTER_CITY_PARAM,
      FILTER_CUISINE_PARAM,
      FILTER_SEARCH_PARAM,
      FILTER_OPEN_PARAM,
      FILTER_PICKUP_PARAM,
      FILTER_DELIVERY_PARAM,
      FILTER_TOP_PARAM,
    ],
  });

  const merchants = useSelector(getNearbyMerchantsData);
  const hasNextPage = useSelector(getNearbyHasNextPage);
  const paginationToken = useSelector(getNearbyPaginationToken);
  const isMerchantsLoading = useSelector(getIsNearbyMerchantsLoading);
  const externalUserId = useSelector(getUserExternalId);
  const incentiveGroups = useSelector(getIncentiveGroups);
  const incentivesCount = useSelector(getTotalIncentivesCount);
  const activeCurrencyCreditMicro = useSelector(getActiveCurrencyCreditsMicro);
  const formattedCredits = useSelector(getActiveCurrencyCredits);
  const showOrderBar = useSelector(hasActiveOrders);
  const activeOrdersInitialized = useSelector(getActiveOrdersInitialized);
  const orderSource = useSelector(getOrderSource);
  const reorderItems = useSelector(getReorderItems);
  const isReorderItemsLoading = useSelector(getIsReorderItemsLoading);

  const cityName = useFormatMessage({
    entry: QUERY_CITIES[initialFilters.city]?.entry,
  });
  const cuisineSearchTerm = useFormatMessage({
    entry: QUERY_CUISINES[initialFilters.queryCuisine]?.searchTerm,
  });
  const subheadingText = useFormatMessage({ entry: 'landing.subheadingText' });
  const logoUrl = useFormatMessage({
    entry: 'landing.logoLink',
  });

  const [filters, setFilters] = useState({
    ...(initialFilters.query && { query: initialFilters.query }),
    ...(initialFilters.queryCuisine && {
      queryCuisine: initialFilters.queryCuisine,
      query: cuisineSearchTerm,
    }),
    ...(initialFilters.latitude && { latitude: initialFilters.latitude }),
    ...(initialFilters.longitude && { longitude: initialFilters.longitude }),
    ...(initialFilters.city && { city: initialFilters.city }),
    ...(initialFilters.isAvailableNow && {
      isAvailableNow: initialFilters.isAvailableNow,
    }),
    ...(initialFilters.hasGroupOrdering && {
      hasGroupOrdering: initialFilters.hasGroupOrdering,
    }),
    ...(initialFilters.isTopMerchant && {
      isTopMerchant: initialFilters.isTopMerchant,
    }),
    ...(initialFilters.fulfillmentType && {
      fulfillmentType: initialFilters.fulfillmentType,
    }),
    ...(initialFilters.hasDelivery && {
      hasDelivery: initialFilters.hasDelivery,
    }),
    ...(cityName && {
      locationQuery: cityName,
    }),
    ...(initialFilters.locationQuery && {
      locationQuery: initialFilters.locationQuery,
    }),
  });
  const [showHeroSection, setShowHeroSection] = useState(!filters.latitude);
  const [isDownloadAppModalOpen, onDownloadAppModalClose] =
    useDownloadAppModal();

  const headingCity = useFormatMessage({
    entry: QUERY_CITIES[filters.city]?.entry,
  });
  const headingCuisine = useFormatMessage({
    entry: QUERY_CUISINES[filters.queryCuisine]?.entry,
  });
  const headingText = useFormatMessage({
    entry: getHeadingTextKey({
      city: filters.city,
      cuisine: filters.queryCuisine,
      catering: filters.hasGroupOrdering,
    }),
    values: { city: headingCity, cuisine: headingCuisine },
  });

  useTrackWebVitals(PAGE_NAME.LANDING);

  useEffectOnce(() => {
    if (initialFilters.fromCookie) {
      updateQuery(filters, persistedQuery);
    }
  });

  useEffectOnce(() => {
    // PM subdomains take priority, default to RITUAL_APP
    const isOrgOrderSource = orderSource === ORDER_SOURCE.ORGANIZATION;

    if (!isOrgOrderSource) {
      dispatch(
        setSource({
          orderSource: ORDER_SOURCE.RITUAL_APP,
          origin: process.env.RT_MARKETPLACE_ORIGIN,
        }),
      );
    }

    logRWHomescreenImpression(
      isOrgOrderSource ? orderSource : ORDER_SOURCE.RITUAL_APP,
    );
  });

  useEffect(() => {
    if (externalUserId && filters.latitude && filters.longitude) {
      dispatch(
        loadNearbyMerchants({
          ...(filters.query && { query: filters.query }),
          latitude: filters.latitude,
          longitude: filters.longitude,
          ...(filters.hasDelivery && { hasDelivery: filters.hasDelivery }),
          ...(filters.hasPickup && { hasPickup: filters.hasPickup }),
          ...(filters.isAvailableNow && {
            isAvailableNow: filters.isAvailableNow,
          }),
          ...(filters.hasGroupOrdering && {
            hasGroupOrdering: filters.hasGroupOrdering,
          }),
          ...(filters.isTopMerchant && {
            isTopMerchant: filters.isTopMerchant,
          }),

          limit: QUERY_LIMIT,
          maxDistance: 5000,
        }),
      );
    }
  }, [externalUserId]);

  const { getBoolean, configLoaded } = useContext(RemoteConfigContext);
  const isQuickOrderLandingPageEnabled = getBoolean(
    'rw_landingpage_quick_reorder',
  );

  useEffect(() => {
    if (
      authenticated &&
      filters.latitude &&
      filters.longitude &&
      configLoaded &&
      isQuickOrderLandingPageEnabled
    ) {
      getCountryCodeFromLatLng({
        lat: parseFloat(filters.latitude),
        lng: parseFloat(filters.longitude),
      }).then(countryCode => {
        const currencyCode = getCurrencyCodeFromCountryCode(countryCode);
        dispatch(updateCurrencyCode(currencyCode));
      });
      dispatch(
        loadReorderItems({
          latitude: filters.latitude,
          longitude: filters.longitude,
        }),
      );
    }
  }, [
    authenticated,
    filters.latitude,
    filters.longitude,
    configLoaded,
    isQuickOrderLandingPageEnabled,
  ]);

  useEffect(() => {
    // check if search has occurred (requires lat/lng) and data is not currently being loaded)
    if (filters?.latitude && filters?.longitude && !isMerchantsLoading) {
      logRWSearchEvent({
        filters,
        numResults: merchants?.length || 0,
      });
    }
  }, [merchants, isMerchantsLoading]);

  const openLoginModal = () => {
    setAuthModal({
      showLoginModal: true,
      newReturnUri: window.location.toString(),
    });
  };

  const loadMerchants = filter => {
    const newFilters = {
      ...(filter.query && { query: filter.query }),
      queryCuisine: filter.queryCuisine,
      latitude: filter.latitude,
      longitude: filter.longitude,
      ...(filter.hasDelivery && { hasDelivery: filter.hasDelivery }),
      ...(filter.hasPickup && { hasPickup: filter.hasPickup }),
      ...(filter.fulfillmentType && {
        fulfillmentType: filter.fulfillmentType,
      }),
      ...(filter.isAvailableNow && { isAvailableNow: filter.isAvailableNow }),
      ...(filter.hasGroupOrdering && {
        hasGroupOrdering: filter.hasGroupOrdering,
      }),
      ...(filter.isTopMerchant && {
        isTopMerchant: filter.isTopMerchant,
      }),
      ...(filter.city && { city: filter.city }),
      ...(filter.locationQuery && { locationQuery: filter.locationQuery }),
    };
    setFilters(newFilters);
    updateQuery(newFilters, persistedQuery);
    if (newFilters.latitude && newFilters.longitude) {
      dispatch(
        loadNearbyMerchants({
          ...newFilters,
          limit: QUERY_LIMIT,
          maxDistance: 5000,
        }),
      );
    }
  };

  const loadMoreMerchants = () => {
    dispatch(
      loadMoreNearbyMerchants({
        ...filters,
        limit: QUERY_LIMIT,
        maxDistance: 5000,
        nextPageToken: paginationToken,
      }),
    );
  };

  const setHeroSectionLocation = ({ lat, lon, city, locationQuery }) => {
    setShowHeroSection(false);
    loadMerchants({
      ...filters,
      latitude: lat,
      longitude: lon,
      city,
      locationQuery,
    });
  };

  const getBanner = () => {
    if (initialized && !authenticated) {
      return (
        <Banner
          rightIcon={<ResponsiveImage id="icons.tag.img" />}
          onClick={openLoginModal}
        >
          <FormattedMessage entry="global.signInBanner" />
        </Banner>
      );
    }
    if (showOrderBar) {
      return <OrderBarBanner />;
    }
    if (incentivesCount > 0 && activeOrdersInitialized) {
      return (
        <CreditIncentiveBanner
          creditMicro={activeCurrencyCreditMicro}
          creditAmount={formattedCredits}
          incentiveCount={incentivesCount}
          incentiveGroups={incentiveGroups}
        />
      );
    }
    return null;
  };

  if (err) return <Error statusCode={err.statusCode} />;

  if (showHeroSection) {
    return (
      <>
        <LandingPageMetadata isDownloadAppModalOpen={isDownloadAppModalOpen} />
        <PageContainer>
          <HeroSection
            setLocation={setHeroSectionLocation}
            headingText={headingText}
          />
          <DownloadAppModal
            isOpen={isDownloadAppModalOpen}
            onClose={onDownloadAppModalClose}
          />
          <Footer />
        </PageContainer>
      </>
    );
  }

  return (
    <>
      <LandingPageMetadata isDownloadAppModalOpen={isDownloadAppModalOpen} />
      <NavBar>
        <a href={logoUrl}>
          <NavLogos logos={['promo.ritualLogo.img']} />
        </a>
        <AccountControls />
      </NavBar>
      {getBanner()}
      <PageContainer>
        <Header heading={headingText} subheading={subheadingText} />
        <FilterBar initialValues={filters} onChange={loadMerchants} />
        {isQuickOrderLandingPageEnabled && (
          <QuickReorder
            items={reorderItems}
            isLoading={isReorderItemsLoading}
            navigateToCheckout
          />
        )}
        <SearchResults
          merchants={merchants}
          isLoading={isMerchantsLoading}
          showMore={loadMoreMerchants}
          hasNextPage={hasNextPage}
          topMerchantsOnly={filters.isTopMerchant}
          searchQuery={filters.query || ''}
          pathParams={{
            city: filters.city || '',
            cuisine: filters.queryCuisine || '',
            catering: filters.hasGroupOrdering,
          }}
          queryParams={getUrlSearchParams({ filter: filters, persistedQuery })}
        />
        <DownloadAppModal
          isOpen={isDownloadAppModalOpen}
          onClose={onDownloadAppModalClose}
        />
        <Footer />
      </PageContainer>
    </>
  );
};

LandingPage.propTypes = {
  err: PropTypes.shape({
    statusCode: PropTypes.number,
  }),
  initialFilters: PropTypes.shape({
    query: PropTypes.string,
    queryCuisine: PropTypes.string,
    latitude: PropTypes.number,
    longitude: PropTypes.number,
    locationQuery: PropTypes.string,
    city: PropTypes.string,
    isAvailableNow: PropTypes.bool,
    hasGroupOrdering: PropTypes.bool,
    hasDelivery: PropTypes.bool,
    isTopMerchant: PropTypes.bool,
    fulfillmentType: PropTypes.string,
  }),
};

export default LandingPage;
